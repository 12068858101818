import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faFileArrowDown } from '@fortawesome/free-solid-svg-icons';

export default function ErrorCard({ id, message, onClose }) {
    const overlayStyle = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.9)', // Fundo escurecido
        zIndex: '999', // Garante que fique atrás do cardStyle
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    const cardStyle = {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '300px',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        zIndex: 1000,
        padding: '20px',
        textAlign: 'center',
    };

    const buttonStyle = {
        padding: '10px 20px',
        backgroundColor: '#4CAF50',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        marginTop: '20px',
        transition: 'background-color 0.3s ease',
    };

    const iconStyle = {
        color: '#4CAF50',
        marginBottom: '20px',
    };

    const closeButtonStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        color: '#aaa',
        cursor: 'pointer',
    };

    return (
        <div style={overlayStyle}>
        <div style={cardStyle}>
            <button onClick={onClose} style={closeButtonStyle}>
                <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            <div>
                <FontAwesomeIcon icon={faFileArrowDown} size="3x" style={iconStyle} />
                <h4>Baixar PDF</h4>
                <p>{message}</p>
                <button onClick={onClose} style={buttonStyle}>Ok, Entendi</button>
            </div>
        </div>
        </div>
    );
}
    