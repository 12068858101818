const Alimentos = ({ handleInputChange }) => {
    document.querySelectorAll('.label-mont input[type="checkbox"]').forEach(input => {
        input.addEventListener('change', function () {
            this.parentNode.style.backgroundColor = this.checked ? '#4CAF50' : '#fff';
            this.parentNode.style.color = this.checked ? 'white' : 'black';
        });
    });

    return (
        <div className="food-options">
            <div className="food-section">
                <h3 class="section-title">Café da Manhã</h3>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox" value="Aveia" onChange={handleInputChange} /> Aveia 🌾</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Cuzcuz" onChange={handleInputChange} /> Cuzcuz 🍚</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Café" onChange={handleInputChange} /> Café ☕</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Crepioca" onChange={handleInputChange} /> Crepioca 🥞</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Frutas" onChange={handleInputChange} /> Frutas 🍎</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Leite" onChange={handleInputChange} /> Leite 🥛</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Ovo" onChange={handleInputChange} /> Ovo Cozido🥚</label>
                <label className="label-mont"><input type="checkbox" name="cadedamanha" className="hidden-checkbox" value="Ovo" onChange={handleInputChange} /> Ovo Mexido🥚</label>
                <label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Ovo" onChange={handleInputChange} /> Omelete 🥚</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Pão Francês + complemento saudavel" onChange={handleInputChange} /> Pão Francês 🥖</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Pão de forma + complemento saudavel" onChange={handleInputChange} /> Pão de Forma 🍞</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Pasta de Amendoin" onChange={handleInputChange} /> Pasta de Amendoin 🌰</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Queijo" onChange={handleInputChange} /> Queijo 🧀</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Tapioca" onChange={handleInputChange} /> Tapioca 🥙</label>
<label className="label-mont"><input type="checkbox" name="cafedamanha" className="hidden-checkbox" value="Suco" onChange={handleInputChange} /> Suco 🥤</label>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox" name="cafedamanha" value="Mel" onChange={handleInputChange} /> Mel 🍯</label>
            </div>

            <div className="food-section">
                <h3 class="section-title">Lanche da Manhã e Tarde</h3>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox" name="lanchedamanha"  value="Frutas" onChange={handleInputChange} /> Frutas 🍎</label>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox"  name="lanchedamanha" value="Iogurte" onChange={handleInputChange} /> Iogurte 🥛</label>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox" name="lanchedamanha"  value="Biscoito Integral" onChange={handleInputChange} /> Biscoito Integral 🍪</label>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox" name="lanchedamanha" value="Barra de Cereais" onChange={handleInputChange} /> Barra de Cereais 🍬</label>
                <label className="label-mont"><input type="checkbox" className="hidden-checkbox" name="lanchedamanha" value="Cereal" onChange={handleInputChange} /> Cereal 🥣</label>
                <label className="label-mont"><input type="checkbox" name="lanchedamanha" className="hidden-checkbox" value="Ovo Cozido" onChange={handleInputChange} /> Ovo Cozido🥚</label>
                <label className="label-mont"><input type="checkbox" name="lanchedamanha" className="hidden-checkbox" value="Ovo Mexido" onChange={handleInputChange} /> Ovo Mexido🥚</label>
                <label className="label-mont"><input type="checkbox" name="lanchedamanha" className="hidden-checkbox" value="Omelete" onChange={handleInputChange} /> Omelete 🥚</label>
                <label className="label-mont"><input type="checkbox" name="lanchedamanha" className="hidden-checkbox" value="Pão de Queijo" onChange={handleInputChange} /> Pão de Queijo 🧀</label>
                <label className="label-mont"><input type="checkbox" name="lanchedamanha" className="hidden-checkbox" value="Queijo" onChange={handleInputChange} /> Queijo 🧀</label>
                <label className="label-mont"><input type="checkbox" name="lanchedamanha" className="hidden-checkbox" value="Rap10" onChange={handleInputChange} /> Rap 10 🌯</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Sanduiche de Frango" onChange={handleInputChange} /> Sanduiche de Frango 🥪</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Sanduiche de Peru" onChange={handleInputChange} /> Sanduiche de Peru 🥪</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Salada de Frutas" onChange={handleInputChange} /> Salada de Frutas 🥗</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Suco" onChange={handleInputChange} /> Suco 🥤</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Pipoca sem oleo e sal" onChange={handleInputChange} /> Pipoca 🥤</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Rolinhos de Peru com Queijo 🌯" onChange={handleInputChange} /> Rolinhos de Peru 🌯</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Rolinhos de Presunto com Queijo 🌯" onChange={handleInputChange} /> Rolinhos de Presunto 🌯</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Torrada Integral" onChange={handleInputChange} /> Torrada Integral 🍞</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Vitamina" onChange={handleInputChange} /> Vitamina 🥤</label>
<label className="label-mont"><input type="checkbox" name="lanchedamanha"className="hidden-checkbox" value="Whey" onChange={handleInputChange} /> Whey 🥛</label>

            </div>
            <div className="food-section">
                <h3 class="section-title">Almoço e Janta</h3>
                <label className="label-mont"><input type="checkbox"  name="almoco" className="hidden-checkbox" value="Arroz" onChange={handleInputChange} /> Arroz 🍚</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Batata Inglesa" onChange={handleInputChange} /> Batata Inglesa 🥔</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Batata Doce" onChange={handleInputChange} /> Batata Doce 🥔</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Carne Vermelha" onChange={handleInputChange} /> Carne 🥩</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Carne de Porco" onChange={handleInputChange} /> Carne-Porco 🐖</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Cuzcuz" onChange={handleInputChange} /> Cuzcuz 🍚</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Feijão" onChange={handleInputChange} /> Feijão 🍲</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Frango" onChange={handleInputChange} /> Frango 🍗</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Inhame" onChange={handleInputChange} /> Inhame 🍠</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Legumes" onChange={handleInputChange} /> Legumes 🥦</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Macarrão" onChange={handleInputChange} /> Macarrão 🍝</label>
 <label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Ovo Cozido" onChange={handleInputChange} /> Ovo Cozido🥚</label>
                <label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Ovo Mexido" onChange={handleInputChange} /> Ovo Mexido🥚</label>
                <label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Omelete" onChange={handleInputChange} /> Omelete 🥚</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Mandioca" onChange={handleInputChange} /> Mandioca 🍠</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Peixe" onChange={handleInputChange} /> Peixe 🐟</label>
<label className="label-mont"><input type="checkbox" name="almoco" className="hidden-checkbox" value="Salada" onChange={handleInputChange} /> Salada 🥗</label>
            </div>

        </div>
    );
}













export default Alimentos;
