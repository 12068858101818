import React from 'react';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

// Criação de uma animação de rotação
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Estilização do spinner
const LoadingSpinnerS = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: var(--backgroundAccentTransparent);
  color: rgb(29, 78, 216);
  border: 5px solid rgba(29, 78, 216, 0.2); /* Cor mais suave para o spinner */
  border-top-color: rgb(29, 78, 216); /* Cor mais forte para a parte superior */
  animation: ${rotate} 1s linear infinite;
`;

/* LoadingSpinner é um componente que exibe um spinner de carregamento customizado */
export default function LoadingSpinner() {
  return (
    <LoadingSpinnerS
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    />
  );
}

