import {React, useEffect} from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from "./styles/Globals";
import StyledToastContainer from "./styles/App.style";
import 'react-toastify/dist/ReactToastify.css';
import initializeMercadoPago from './services/mecadopago.helper';
import Content from "./routes/Content";
import { lightTheme } from "./styles/Themes";
import { Helmet } from 'react-helmet';

/* Inicializa o Mercado Pago */
initializeMercadoPago();


function App() {
  // const { theme } = UserAuth();
  useEffect(() => {
    
    const disableRightClick = (event) => {
      event.preventDefault();
    };

    const disableF12 = (event) => {
      if (event.key === 'F12') {
        event.preventDefault();
      }
    };

    const disableShiftRightClick = (event) => {
      if (event.shiftKey) {
        event.preventDefault();
      }
    };

    const disableCtrl = (event) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };

    document.addEventListener('contextmenu', disableRightClick);
    document.addEventListener('contextmenu', disableShiftRightClick);
    document.addEventListener('keydown', disableF12);
    document.addEventListener('keydown', disableCtrl);

    return () => {
      document.addEventListener('contextmenu', disableRightClick);
      document.addEventListener('contextmenu', disableShiftRightClick);
      document.addEventListener('keydown', disableF12);
      document.addEventListener('keydown', disableCtrl);
    };

  
  
}, []);
  return (
    <div>
      <ThemeProvider theme={lightTheme}>
        <Helmet>
          <meta name='description' content="Nutri Facil | A Facilidade de Montar Dieta com Nutri Virtual"/>
        </Helmet>
        <StyledToastContainer />
        <GlobalStyle />
        <Content />
      </ThemeProvider>
    </div>
  );
}

export default App;
