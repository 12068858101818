import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../../context/AuthContext';
import "../Signin.css"// Importe a imagem aqui

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [termoscheck, setTermosCheck] = useState(false)
  const [error, setError] = useState('');
  const { createUser } = UserAuth();
  const navigate = useNavigate();

  const handleCheck = () => {
    if (termoscheck) {
      setTermosCheck(false);
    } else {
      setTermosCheck(true);
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');



    try {
      if (password !== confirmPassword) {
        setError('As senhas não são iguais. Por favor, verifique novamente.');
        return;
      } else

        if (!termoscheck) {
          setError("Você deve concordar com os termos e condições para criar uma conta");
          return;
        } 

        else {
          await createUser(email, password);
          navigate('/home')
        }
    } catch (e) {
      if (e.message === "Firebase: Error (auth/email-already-in-use).") {
        setError("Email já existente. Faça o login ou crie com outro email");
      } else if (e.message === "Firebase: Password should be at least 6 characters (auth/weak-password).") {
        setError("Sua senha precisa ter mais de 6 caracteres")
      }
    }
  };

  return (
    <div className="flex h-screen items-center justify-center bg-[#fefefe] inter">
    <div className="w-full lg:w-3/5 xl:w-2/5 mx-auto p-3 flex flex-col">
    <div className="text-center">
        <h2 className='text-4xl mb-3'>📲</h2>
      <h2 className='font-bold text-2xl my-3'> Crie sua conta</h2>
      </div>
      <form onSubmit={handleSubmit} className="px-7 pt-6 pb-8 mb-4 inter">
        <div className="mb-6">
        <label className="block text-gray-700 text-sm md:text-base font-medium md:font-base mb-2">Email</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            placeholder="Seu email"
            required
          />
        </div>
        <div className="mb-6">
        <label className="block text-gray-700 text-sm md:text-base font-medium inter">Senha</label>
          <div className="relative">
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type={showPassword ? 'text' : 'password'}
              placeholder="Sua senha"
              required
            />
            <span
              className="absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? '👁️' : '👁️‍🗨️'}
            </span>
          </div>
        </div>
        <div className="mb-6">
        <label className="block text-gray-700 text-sm md:text-base font-medium">Confirmar Senha</label>
          <input
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type={showPassword ? 'text' : 'password'}
            placeholder="Confirme sua senha"
            required
          />

        </div>
        <div className='mx-3 my-4  '>
          <input type="checkbox" className="mr-3" onChange={(e) => handleCheck(e.nativeEvent)} />
          <label>
            Eu estou de acordo com os{' '}
            <Link to="/termos" target="_blank">
              Termos e Condições de uso
            </Link>{' '}
            da Dieta Inteligente
          </label>
        </div>
        {error && <p className="text-red-600 mb-4">{error}</p>}
        <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full">
              CRIAR CONTA
            </button>
        <div className="mt-2 my-6 text-center">
          <p className='my-3'>Já possui uma conta?</p>
          <Link to="/">
          <button className="shadow-md appearance-none border-2 rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline font-bold">
              ENTRAR COM CONTA
            </button>
          </Link>
        </div>
      </form>
    </div>
    </div>
  );
};

export default Signup;
