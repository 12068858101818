import { TailSpin } from 'react-loader-spinner';
import '../NewMetodo/StylesDiet.css'
const Spinner = ({loadingProgress}) => {
    return ( <div className="overlay">
    <div className="Spinner">

      <div className="ComidaEmoji">🍎</div>

      <TailSpin
        color="rgb(29, 78, 216)"
        height={80}
        width={80}
      />
      <p>Carregando ⏳...{loadingProgress}%</p>
      <p className="AvisoNaoSaia">ATENÇÃO, não saia da página enquanto geramos sua dieta 🥗.</p>
    </div>
  </div> );
}

export default Spinner ;