import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';
import '../NewMetodo/StylesDiet.css';
import ProposalModal from '../NewMetodo/ProposalModal';
import { saveUserInfo, getUserInfo, removeUserInfo} from '../../helpers/localStorage.helper';
import SubscriptionComponent from "../MercadoPago/SubscriptionComponent/Subscription.component";
import { toast } from "react-toastify";
import { handlePaymentSuccess } from '../../context/ApiRequest'; // 
import DonwloadPDFDieta from "./DownloadPDF";
import { UserAuth } from "../../context/AuthContext";
import { IMaskInput } from "react-imask";
import Menu from "../Menu/Menu";
import Mensagem from "../SaveMsg/Mensagem";
import Spinner from "../Spinner/Spinner";
import Alimentos from "./Alimentos/Alimentos";


export default function MontarDietaDN() {
  const {retornaTicket, retornaDieta, adicionarDieta, retirarTicket, adicionarTicketUsado} = UserAuth()
  const resultTextareaRef = useRef(null); 
  
  const [UserDieta, setUserDieta] = useState('')
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const[isOpen, setIsOpen] = useState(false)
  const [isVisiblePayment, setIsVisiblePayment] = useState(false);
  const [recomend, setRecomend] = useState(false)
  const [aguaRecomendada, setAguaRecomendada] = useState('')
  const [msgSave, setMsgSave] = useState(true)
  const [mensagem, setMensagem] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("");
  const [error2, setError2] = useState("");
  const [imc, setImc] = useState('')
  const [answer, setAnswer] = useState('')
  const [ticketsUsuario, setTicketUsuario] = useState('')
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [formData, setFormData] = useState({
    peso: '',
    altura: '',
    idade: '',
    genero: '',
    objetivo: '',
    cafedamanha: [],
    lanchedamanha: [],
    almoco: [],
  
  });

  useEffect(() => {
    retornaDieta()
      .then(async (dieta) => {
        if (dieta !== null) {
          await setUserDieta(dieta);
        } else {
          setUserDieta('');
        }
        
      })
      .catch((error) => {
        console.error('Erro ao obter a Dieta:', error);
        setUserDieta('Erro ao obter a Dieta');
      });
    },[retornaDieta])

   

useEffect(()=>{
  retornaTicket().then((ticket) => {
    setTicketUsuario(ticket)
  }).catch(console.log("Erro ao buscar ticket"))
},[retornaTicket])

  const toggleModal = (isOpen, goToPayment = false) => {
    setShowModal(isOpen);
    if (!isOpen && goToPayment) {
      setIsVisiblePayment(true);
      const user = getUserInfo()
      console.log(user)
      setStep(3);
    } else if (!isOpen) {
      setIsVisiblePayment(false);
    }
  };

  const handleSave = () => {
    adicionarDieta(resultTextareaRef.current.value);
    setMsgSave(false)
    return;
  };


  useEffect(() => {
    removeUserInfo()
    saveUserInfo()
  },[])

  const myDivRef = useRef(null); 
  const calcularAguaNecessaria = () => {
    const agua = formData.peso ? 0.03 * formData.peso : 0;
    setAguaRecomendada(agua);
  };
  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
   
  };

  const handleInputChangeAli = (event) => {
    const { name, value } = event.target;
    
    // Verifica se o array já contém o valor
    const currentArray = formData[name] || [];
    let newArray;
    if (currentArray.includes(value)) {
      // Remove o valor se já estiver presente
      newArray = currentArray.filter(item => item !== value);
    } else {
      // Adiciona o valor se não estiver presente
      newArray = [...currentArray, value];
    }
  
    // Atualiza o formData com o novo array
    setFormData({
      ...formData,
      [name]: newArray
    });
  
    // Opcional: log para verificação
    console.log(formData.cafedamanha);
  };

  const nextStep = () => {
    if (step === 1){
      if (!formData.peso || !formData.altura || !formData.idade || !formData.genero || !formData.objetivo ) {
        setError("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
    }
    if (step === 2) {
      if (!formData.objetivo) {
        setError2("Por favor, preencha o campo de Objetivo obrigatório.");
        return;
      }else{
        if(ticketsUsuario === 0 || ticketsUsuario === null){
          setShowModal(true);
        }else{
          setStep(step+1)
        }
     
      }
    } else if (step <= 3) {
      setStep(step + 1);
    }
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      if (step === 3) {
        setIsVisiblePayment(true);
      }
    }
  };

  
    const handlePaymentSuccessLocal = async () => {
      setStep(4)
      adicionarTicketUsado()
      if (ticketsUsuario !== 0){
        retirarTicket()
      }
      await handlePaymentSuccess(formData.altura, formData.peso, formData.objetivo,formData.genero, formData.cafedamanha,formData.lanchedamanha,formData.almoco, setIsLoading, setLoadingProgress, setIsVisiblePayment, setImc, setMensagem, setRecomend, calcularAguaNecessaria, setAnswer);
    };
  
 

  const handlePaymentWaiting = () => {
    toast.info('Pagamento pendente. Por favor, finalize o pagamento via Pix e volte para essa página!');
  };

  const amount = 7.99; 

  return (
    <div className="form-container inter">
      <div className="w-full flex flex-row justify-center items-center mb-3">
      <h1 className="font-bold text-2xl">Dieta Inteligente🍎</h1>
      </div>
      <div className="progress-container z-10 relative">
        <div className={`progress ${step === 1 ? 'step1' : step === 2 ? 'step2' : step === 3 ?'step3' : 'step4'} `}></div>
      </div>

      {step === 1 && (
        <div>
         <div className="text-center mb-6">
        <h2 className='text-3xl my-3'>🍎</h2>
<h2 className='font-bold text-xl my-3 mb-6'> Faça sua dieta</h2>
      </div>
          <input
            className="style-input input-mont"
            type="number"
            placeholder="Peso"
            name="peso"
            value={formData.peso}
            onChange={handleInputChange}
          />
          
              <IMaskInput
            className="style-input input-mont"
            mask="0.00"
            type="number"
            placeholder="Altura (m)"
            name="altura"
            maxLength={4}
            value={formData.altura}
            onChange={handleInputChange}
            
          />

          <input
            className="style-input input-mont"
            type="number"
            name="idade"
            placeholder="Idade"
            value={formData.idade}
            onChange={handleInputChange}
          />
  <select
            className="style-input"
            name="objetivo"
    value={formData.objetivo}
    onChange={handleInputChange}
  >
    <option value="" disabled>Selecione seu objetivo</option>
    <option value="Emagrecimento">Emagrecimento</option>
    <option value="Ganho de massa muscular">Ganho de massa muscular</option>
    <option value="Definição muscular">Definição muscular</option>
    <option value="Definição + Ganho de massa muscular">Definição + Ganho de massa muscular</option>
    <option value="Jejum intermitente">Jejum intermitente</option>
    <option value="Emagrecimento + Ganho de massa muscular">Emagrecimento + Ganho de massa muscular</option>
  </select>
          <select name="genero" 
            className="style-input input-mont"
            value={formData.genero} onChange={handleInputChange}>
            <option value="" disabled>Selecione seu gênero</option>
            <option value="Masculino">Masculino</option>
            <option value="Feminino">Feminino</option>
          </select>
          {error && (
        <div>
          <p className="text-md font-bold text-red-500 text-center">{error}</p>
        </div>
      )}
        </div>
        
      )}
      {step === 2 && (
        <div>
<h1 class="header-verde">
    Alimentos favoritos
</h1>
<br></br>
<p class= 'paragrafo'>Clique nos alimentos para selecionar</p>
          <Alimentos handleInputChange={handleInputChangeAli}></Alimentos>
          {error2 && (
        <div>
          <p className="text-md font-bold text-red-500 text-center">{error2}</p>
        </div>
      )}
        </div>
      )}
      {step === 3 && isVisiblePayment && ticketsUsuario === 0 ? (
        <div ref={myDivRef} className="payment-method-selector">
          <SubscriptionComponent
            amount={amount}
            onPaymentSuccess={handlePaymentSuccessLocal}
            onPaymentWaiting={handlePaymentWaiting}
            addOrderBump={true}
            linkexterno={true}
          />
        </div>
      ) : (<div>{step === 3 && (<div className="flex flex-col justify-center text-center items-center">
        <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500"></FontAwesomeIcon>
        <h1 className="my-3">Houve um imprevisto na geração da sua dieta<br></br> Clique no botão abaixo para que possa gerar novamente !</h1>
        <button className="button-mont" onClick={() => handlePaymentSuccessLocal()}>Gerar Novamente</button>
      </div>)}</div>)}
      <div className={`button-group px-4`}>  
      
        {step > 1 && step !== 4 &&(
          <button className="bg-green-600 hover:bg-green-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full z-10" onClick={prevStep}>
            <FontAwesomeIcon className="mr-2"  icon={faArrowLeft} />
            Voltar
          </button>
        )}
        {step <= 2 ? (<button className="bg-green-600 hover:bg-green-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full z-10" onClick={nextStep}>
          {step <= 2 ? 'Próximo' : ''}
          <FontAwesomeIcon  className="ml-2" icon={faArrowRight} />
        </button>
        ) : (answer && (<DonwloadPDFDieta 
          dieta={answer}
          altura={formData.altura}
          peso={formData.peso} 
          imc={imc}
          mensagem={mensagem}
          aguaRecomendada={aguaRecomendada} >
            
          </DonwloadPDFDieta>)
      )  } 
      
      {isLoading && (
       <Spinner loadingProgress={loadingProgress}></Spinner>
      )}

      </div>
      <div>
      {recomend && step === 4 && (<div className="chat-response">
        <label htmlFor="response" className=" font-bold text-xl text-center">Dieta Personalizada</label>
        <textarea className="w-full p-4"
          id="response"
          ref={resultTextareaRef}
          name="response"
          rows="100"
          value={answer}
          readOnly
        >
        </textarea>
        
        <Mensagem msgSave={msgSave} handleSave={handleSave}></Mensagem>
      </div>)}

      {step===1 && (
     <Menu isOpen={isOpen} setIsOpen={setIsOpen} UserDieta={UserDieta} ></Menu>
)}
      <ProposalModal
        isOpen={showModal}
        onRequestClose={() => toggleModal(false)}
        onConfirm={() => toggleModal(false, true)}
      />
    </div>
</div>);
}
