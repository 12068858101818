import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';
import './Signin.css'



const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { signIn } = UserAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await signIn(email, password);
      navigate('/home');
    } catch (e) {
      if (e.message === "Firebase: Error (auth/user-not-found).") {
        setError("Email Incorreto")
      } else if (e.message === "Firebase: Error (auth/wrong-password).") {
        setError("Senha Incorreta")
      }else if (e.message === "Firebase: Error (auth/invalid-login-credentials).") {
        setError("Email ou Senha Invalida, Lembre seus dados cadastrados!")
      } else {
        setError(e.message)
      }
    }
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError('');

  //   try {
  //     // Simula um login bem-sucedido sem verificar email ou senha
  //     navigate('/home');
  //   } catch (e) {
  //     setError("Ocorreu um erro ao fazer login");
  //   }
  // };

  return (
    <div className="flex h-screen items-center justify-center bg-[#fefefe] inter">
    <div className="w-full lg:w-3/5 xl:w-2/5 mx-auto p-3 flex flex-col">
      <div className="text-center">
        <h2 className='text-4xl my-3'>📲</h2>
<h2 className='font-bold text-2xl my-3'> Faça Login com sua conta</h2>
      </div>
      <form onSubmit={handleSubmit} className=" px-7 pt-6 pb-8 mb-4">
        <div className="mb-6">
          <label className="block text-gray-700 text-sm md:text-base mb-2 font-bold">E-mail</label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            type="email"
            placeholder="Seu email"
            required
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm md:text-base font-bold mb-2">Senha</label>
          <input
            onChange={(e) => setPassword(e.target.value)}
            className="shadow appearance-none border rounded w-full py-3 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            type="password"
            autoComplete="current-password"
            placeholder="Sua senha"
            required
          />
        </div>
        {error && <p className="text-red-500 text-sm italic text-center mb-4">{error}</p>}
        <button className="bg-green-600 hover:bg-green-600 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full">
          ENTRAR
        </button>
        <div className="mt-6 text-center">
          <a href="/redefinirsenha" className="inline-block align-baseline font-bold text-sm text-button-green hover:text-green-700">
            Esqueceu sua senha?
          </a>
        </div>
        <div className="mt-6 text-center">
        <Link to="/signup">
          <button className="flex text-sm justify-center shadow-md appearance-none border rounded w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
            <p>Não possui uma conta? <strong>CRIAR CONTA</strong></p>
          </button>
          </Link> 
        </div>
      </form>
    </div>
  </div>
  )
};


export default Signin;
