import ReactModal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import '../NewMetodo/CallAction.css';

const ProposalModal = ({ isOpen, onRequestClose, onConfirm }) => {
    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={() => onRequestClose(false)}
            contentLabel="Proposta Modal"
            className="proposal-modal"
            overlayClassName="proposal-modal-overlay"
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Montar sua Dieta</h2>
                    <button className="close-button" onClick={() => onRequestClose(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <div className="modal-body">
                <p>Monte sua dieta com seus alimentos favoritos por apenas <b>R$ 7,99.</b> Deseja começar agora?</p>
                </div>
                <div className="modal-footer">
                    <button className="yes-button" onClick={() => onConfirm(true)}>
                        <FontAwesomeIcon icon={faCheck} className='mr-2' />
                        Sim
                    </button>
                    <button className="no-button" onClick={() => onRequestClose(false)}>
                        <FontAwesomeIcon icon={faTimes} className='mr-2' />
                        Não
                    </button>
                </div>
            </div>
        </ReactModal>
    );
};

export default ProposalModal;
