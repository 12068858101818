

import axios from 'axios';

 

const sendRequestTreinoF = async (data) => {
 try {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarDietaTreinoF`, { data: data });
   
   return result.data.answer;
 } catch (error) {
   console.error('Erro ao enviar a requisição:', error);
   throw error;
 }
};

const sendRequestTreinoM = async (data) => {
 try {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarDietaTreinoM`, { data: data });
    return result.data.answer;
 } catch (error) {
   console.error('Erro ao enviar a requisição:', error);
   throw error;
 }
};

const sendRequest = async (data) => {
 try {
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/GerarDieta`, { data: data });
   return result.data.answer;
 } catch (error) {
   console.error('Erro ao enviar a requisição:', error);
   throw error;
 }
};

export const handlePaymentSuccess = async (formDataaltura, formDataPeso, formDataObjetivo,formDatagenero,formDatacafe,formDatalanche1,formDataalmoco, setIsLoading, setLoadingProgress, setIsVisiblePayment, setImc, setMensagem, setRecomend, calcularAguaNecessaria, setAnswer) => {

 if (!formDataaltura || !formDataPeso || !formDataObjetivo) {
     console.log("Por favor, preencha todos os campos obrigatórios.");
     return;
   }
 
   setIsLoading(true);
   document.body.classList.add("scroll-lock");
 
   const data = ` Por favor crie somente o plano de dieta para ${formDataObjetivo} seguindo o modelo:
   Por favor faça variações dos alimentos e as refeições do (Almoço e Janta).
   Coloque todas as quantidades em gramas e informe as CALORIAS no final da opção a seguir:
   Utilize como base os alimentos informados abaixo variando em entre duas opções para cada refeição Café da Manha ${formDatacafe}, 
   Lanche da Manha e Lanche da Tarde: ${formDatalanche1}, 
   Almoço e Janta: ${formDataalmoco}, 
    seja coerente nas combinações e utilize apenas esses alimentos escolhidos.
   Leve em consideração minhas informaçoes Altura: ${formDataaltura} m, Peso: ${formDataPeso} kg e Gênero: ${formDatagenero}
   Não é necessario calcular a caloria total da dieta. Não coloque recomendações e nem informações importantes.
   `;

   console.log(data)
 
   try {
     setIsVisiblePayment(false);
     let result;
     if (formDatagenero === 'Feminino') {
       const interval = setInterval(() => {
         setLoadingProgress(prevProgress => {
           if (prevProgress >= 99) {
             clearInterval(interval);
             return prevProgress; // Mantém o progresso em 90% até a função terminar
           }
           return prevProgress + 1; // Incrementa 10% a cada intervalo
         });
       }, 700);
       result = await sendRequestTreinoF(data);
       setLoadingProgress(100); // Completa a barra de progresso
       clearInterval(interval);
 
     } else if (formDatagenero === 'Masculino') {
       const interval = setInterval(() => {
         setLoadingProgress(prevProgress => {
           if (prevProgress >= 99) {
             clearInterval(interval);
             return prevProgress; // Mantém o progresso em 90% até a função terminar
           }
           return prevProgress + 1; // Incrementa 10% a cada intervalo
         });
       }, 700);
       result = await sendRequestTreinoM(data);
       setLoadingProgress(100); // Completa a barra de progresso
       clearInterval(interval);
 
     } else {
       const interval = setInterval(() => {
         setLoadingProgress(prevProgress => {
           if (prevProgress >= 99) {
             clearInterval(interval);
             return prevProgress; // Mantém o progresso em 90% até a função terminar
           }
           return prevProgress + 1; // Incrementa 10% a cada intervalo
         });
       }, 700);
       result = await sendRequest(data);
       setLoadingProgress(100); // Completa a barra de progresso
       clearInterval(interval);
     }
     const imc = formDataPeso / (formDataaltura * formDataaltura);
     setImc(imc.toFixed(1));
     calcularAguaNecessaria();
     if (imc < 18.5) {
       setMensagem("Você está abaixo do peso");
     } else if (imc >= 18.5 && imc <= 24.9) {
       setMensagem("Você está com peso normal");
     } else if (imc >= 25 && imc <= 29.9) {
       setMensagem("Você está com sobrepeso");
     } else if (imc >= 30) {
       setMensagem("Você está com obesidade");
     } else {
       setMensagem("Por favor, atualize seus dados de peso e altura.");
     }
     setRecomend(true);
     setAnswer(result);
   
   } catch (err) {
     console.error(err);
     console.log('Estamos com bastante requisições! Coloque suas informações novamente para gerar sua dieta');
   } finally {
     setIsLoading(false);
     document.body.classList.remove('scroll-lock'); // Remova a classe
   }
 };