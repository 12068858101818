
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Signin from "../components/Sign/Signin";
import Signup from "../components/Sign/SignUp/Signup";
import ProtectedRoute from "../routes/ProtectedRoute";
import RedefinePassword from "../components/RedefinePassword/RedefinePassword";
import MontarDietaDN from '../components/NewMetodo/MontarDietaDN';
import Termos from '../components/Termos/Termos'
import Suporte from '../components/SuporteFaleConosco/Suporte'
import LocalDieta from '../components/LocalDieta/LocalDieta'
import Agradecimentos from '../components/Kiwify/Agradecimento'

function App() {

  return (
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/redefinirsenha" element={<RedefinePassword />} />
      <Route path="/termos" element={<Termos />} />
      <Route path='/suporte' element={<ProtectedRoute><Suporte></Suporte></ProtectedRoute>}></Route>
      <Route path="/obrigado" element={<ProtectedRoute><Agradecimentos></Agradecimentos></ProtectedRoute>} />
      <Route path="/dieta" element={<ProtectedRoute><LocalDieta></LocalDieta></ProtectedRoute>} />


      <Route
        path="/home"
        element={
          <ProtectedRoute>
            <MontarDietaDN />
          </ProtectedRoute>
        }
      />
    

    </Routes>
  );
}

export default App;
