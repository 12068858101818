const Mensagem = ({msgSave, handleSave}) => {
    return ( <>
    {msgSave && (<div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}>
        <div style={{
          backgroundColor: 'white',
          borderRadius: '10px',
          padding: '20px',
          maxWidth: '500px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          border: '1px solid #ddd',
          position: 'relative'
        }}>
          <div style={{
            position: 'absolute',
            top: '-25px',
            right: '20px',
            backgroundColor: '#FF4D61',
            color: 'white',
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px',
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)'
          }}>
            ⚠️ {/* Ícone de aviso */}
          </div>
          <h3 style={{
            textAlign: 'center',
            color: '#333',
            fontWeight: 'bold',
            marginBottom: '15px'
          }}>
            Dieta Gerada com Sucesso
          </h3>
          <p style={{
            textAlign: 'center',
            color: '#555',
            marginBottom: '20px'
          }}>
            Clique no botão abaixo para salvar sua dieta e visualizá-la. Depois você poderá baixar em PDF.
          </p>
          <div style={{ textAlign: 'center' }}>
            <button onClick={handleSave} style={{
              backgroundColor: '#FF4D61',
              color: 'white',
              padding: '10px 20px',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer'
            }}>
              SALVAR MINHA DIETA
            </button>
          </div>
        </div>
      </div>
     )}
    </>)
}

export default Mensagem;