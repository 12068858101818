import styled from 'styled-components';

const SubscriptionS = styled.div`
  /* border: 2px solid blue; */
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%; /* Alterado de 100vw para 100% para evitar overflow horizontal */
  min-height: auto; /* Remove a altura mínima para permitir que o conteúdo defina a altura */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Ajustado de center para flex-start para alinhar o conteúdo ao topo */
  text-align: center;
  background-color: var(--background); 

  h1 {
    font-size: 2.5rem;
    color: #00b3ff;
    margin-bottom: 1rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  }
  p {
    font-size: 1.2rem;
    color: var(--textColor);
    margin-bottom: 0.5rem; /* Adicione um espaço abaixo do parágrafo */
    padding-bottom: 16px;
    padding-top: 0;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    font-weight: bold; /* Tornar o texto em negrito */
  }
  
  /* Seletor específico para o parágrafo em questão */
  .amount {
    font-size: 1.0rem; /* Tamanho da fonte */
    color: #666; /* Cor cinza */
    font-weight: normal; /* Texto normal, sem negrito */
    margin-bottom: 1rem; /* Espaço abaixo do parágrafo */
    padding: 0.8rem 1rem; /* Aumento do padding para mais altura */
    border-radius: 5px; /* Bordas arredondadas */
    background-color: #fff; /* Fundo branco */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
    display: inline-block; /* Faz o parágrafo se comportar como um bloco inline */
  }
  
  
  
  
  

  form.mp-checkout-bricks__payment-form {
    padding-top: 0; !important; /* Tente remover o padding superior */
    margin: 0;
  }

  .mp-checkout-bricks__payment-form.svelte-cq90g3.visible-3oS3IS {
    padding:0;
  }
  

  button {
    display: block;
    margin: auto auto 0;
    background: var(--buttonSecondary);
    /* border: 1px solid var(--buttonBorder); */
    border-radius: 4px;
    color: var(--buttonText);
    cursor: pointer;
    padding: 0.7rem 1rem;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color var(--transitionSpeed);

    &.primary {
      background-color: var(--buttonPrimary);
      
      &:hover, &:focus {
        background-color: var(--buttonPrimaryHover);
      }

      &:disabled {
        cursor: not-allowed;
        filter: saturate(0);
        background-color: var(--buttonBackgroundDisabled);
      }
    }

    &.secondary {
      background-color: var(--buttonSecondary);
      
      &:hover, &:focus {
        background-color: var(--buttonSecondaryHover);
      }
    }
  }
`;

export default SubscriptionS;
