import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faArrowRightFromBracket, faFileArrowDown, faGear } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import '../NewMetodo/StylesDiet.css';
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const Menu = ({isOpen, setIsOpen, UserDieta}) => {
    const navigate = useNavigate()
    const {logout} = UserAuth()
    const wpp = () => {

        const numeroum = "5524999375062"
        const numerodois = "5524992178767"
    
        const numeros = []
        function selecionarNumeroAleatoriamente() {
    
          numeros.push(numeroum);
          numeros.push(numerodois);
    
    
          const indiceAleatorio = Math.floor(Math.random() * numeros.length);
          return numeros[indiceAleatorio];
        }
    
    
        const whatsappURL = `https://api.whatsapp.com/send?phone=${selecionarNumeroAleatoriamente()}`;
    
        // Abre uma nova janela ou guia para iniciar a conversa no WhatsApp
        window.open(whatsappURL, '_blank');
      };
    
    return (  <div className="container">
    {isOpen && <div className={isOpen ? "overlay" : ""} onClick={() => setIsOpen(false)}></div>}
    <div className={`button-container ${isOpen ? 'open' : ''}`}>
        {UserDieta && (<button className="round-button flex flex-col" onClick={() => navigate('/dieta')}>
          <FontAwesomeIcon icon={faFileArrowDown} size='lg'></FontAwesomeIcon>
          <p className="text-sm font-bold">Dieta</p>
        </button>)}
        <button className="round-button flex flex-col" onClick={() => wpp()}>
          <FontAwesomeIcon icon={faWhatsapp} size='lg'></FontAwesomeIcon>
          <p className="text-sm font-bold">Suporte</p>
        </button>
        <button className="round-button flex flex-col" onClick={() => logout()}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg"></FontAwesomeIcon>
          <p className="text-sm font-bold">Sair</p>
        </button>
    </div>
    <button className="round-button main-button" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={faGear} size="xl" className={`gear-icon ${isOpen ? 'rotate' : ''}`} />

    </button>
</div> );
}

export default Menu;